@import url('https://fonts.googleapis.com/css2?family=Alef:wght@400;700&display=swap');

@import "../css/tailwindcss-dist.css";

@import "/node_modules/@fortawesome/fontawesome-free/css/all.min.css";

@import "/node_modules/@glidejs/glide/dist/css/glide.core.min.css";

@import "/node_modules/@glidejs/glide/dist/css/glide.theme.min.css";

//@import "/node_modules/css-tooltip/dist/css-tooltip.min.css";

.d-none{
    display: none!important;
}

.feedback-error {
    font-size: 1.1rem;
    font-weight: bolder;
    color: tomato;
    margin-top: .5rem;
}

.require-label{
    color: tomato;
    font-weight: bold;
}

html {
    scroll-behavior: smooth;
}

.backdrop{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.388);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30;
    display: none;

    &.show{
        display: block;
    }
}

.form-group-inline {
    display: flex;
    gap: 1rem;

    @media (max-width:570px) {
        flex-wrap: wrap;

        >div {
            width: 100%;
        }
    }
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    flex-grow: 1;
}

.form-control {
    border: 2px solid gainsboro;
    font-size: 1.2rem;
    padding: .5rem;

    &:focus {
        outline: none;
        border: 2px solid orange;
    }
}

.modal{
    position: fixed;
    z-index: 31;
    background-color: white;
    max-width: 900px;
    min-height: max-content;
    max-height: 70vh;
    top: -100rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem;
    border-radius: 10px;
    transition: top .5s;

    &.show{
        top: 5rem;
    }

    .modal-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.5rem;
        border-bottom: 5px solid orange;
        padding-bottom: 1rem;

        .btn-close-modal{
            background-color: rgb(50, 50, 50);
            color: white;
            width: 40px;
            height: 40px;
            overflow: hidden;
            font-size: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            padding-bottom: 10px;
            cursor: pointer;
            position: absolute;
            top: -16px;
            right:-13px;
        }
    }

    &.modal-faq{
        .modal-body{
            text-align: justify;
        }

        li{
            margin-bottom: 1rem;
        }
    }

    .modal-body{
        margin-top: 1.5rem;
        max-height: 50vh;
        overflow-y: auto;
    }

    @media (max-width:900px) {
        width: 90%;
    }
}

.modal-send-answer{
    max-height: max-content;
    //min-height: 60vh;
    width: 510px;

    .feedback-error{
        font-size: .8rem;
    }

    .form-control {
        padding: .3rem;
        font-size: 1rem;
    }

    .description {
        height: 200px;
    }

    .btn-send-my-answer{
        display: block;
        color: #fff;
        background-color: #00a2ff;
        border-radius: 5px;
        align-self: flex-start;
        margin-top: .1rem;
        padding: .3rem 3rem;
        font-size: 1rem;
        margin-inline: auto;
    }
}

.modal-reclama,
.modal-denuncia {
    max-height: max-content;
    //min-height: 60vh;
    width: 610px;

    .feedback-error{
        font-size: .8rem;
    }

    @media (max-width:645px) {
        width: 95%;
    }

    .modal-title{

        @media (max-width:500px) {
            font-size: 1.2rem;
        }
    }
    .modal-body{
        position: relative;
        margin-top: .4rem;
        max-height: 55vh;
        overflow-y: auto;
        padding-bottom: 1rem;
    }
    .step-container{
        display: flex;
        gap: 2.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        justify-content: center;

        @media (max-width:640px) {
            display: none;
        }

        @media (max-width:500px) {
            justify-content: flex-start;
            overflow-x: auto;
        }
    }

    .step-item-container{
        display: flex;
        flex-direction: column;
        align-items: center;

        .step-item{
            font-size: 1.5rem;
            width: 50px;
            height: 50px;
            border: 1px solid gray;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            background-color: gray;
            font-weight: bold;
            color: white;
            cursor: pointer;

            &.active{
                background-color: orange!important;
                border-color: orange!important;
            }

            &.error{
                background-color: tomato;
                border-color: tomato;
            }

            &.complete{
                background-color: green;
                border-color: green;
            }
        }

        .step-label{
            font-weight: bold;
            margin-top: .2rem;
        }
    }

    .form-container-step{
        padding-inline: 1.2rem;

        .feedback-error {
            font-size: .8rem;
        }
    
        .form-control {
            padding: .3rem;
            font-size: 1rem;
        }
    }

    .step-tab-container{

        max-height: 340px;
        overflow-y: auto;

        border-top: 2px solid rgba(192, 192, 192, 0.779);
        //border-bottom: 2px solid rgba(192, 192, 192, 0.779);
        padding-bottom: 1rem;

        @media (max-width:640px) {
            border-color: transparent;
        }

        .step-tab{
            min-width: 500px;
            position: relative;

            @media (max-width:612px) {
                min-width: 100%;
            }
        }

        .step-final{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            .success-feedback{
                background-color: rgb(35, 192, 35);
                color: white;
                padding: .3rem;
                display: flex;
                gap: 1rem;
                align-items: center;
                border: 3px solid rgba(0, 0, 0, 0.438);
                border-radius: 5px;
                margin-bottom: 10px;
            }

            .label-title{
                font-weight: bold;
            }

            .btn-save-claim-info{
                background-color: #00a2ff;
                color: white;
                padding: 5px;
                border-radius: 5px;
                margin-top: 10px;
            }
        }

        .step-title{
            text-align: center;
            font-size: 1.2rem;
            margin-top: 1rem;
            color: rgb(79, 79, 79);
            margin-bottom: 1rem;
        }

        .container-type-btn{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 2rem;

            .btn-type{
                font-size: 1rem;
                font-weight: bold;
                color: tomato;
                background-color: transparent;
                border: 2px solid tomato;
                padding: .5rem;
                border-radius: 10px;
                transition: .3s all;

                &:hover{
                    background-color: tomato;
                    color: white;
                }
            }
        }

        .container-anonymous {
            display: flex;
            justify-content: center;
            gap: 2rem;
            margin-bottom: 1.5rem;

            .btn-anonymous{
                font-size: 1rem;
                border: 2px solid gray;
                color: gray;
                padding: .2rem;
                border-radius: 10px;

                &.selected{
                    border: 2px solid orange;
                    color: orange;
                }
            }
        }

        .description{
            height: 150px;
        }
    }

    .container-btn-navigation{
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        margin-top: 1rem;

        .btn-prev,.btn-next,.btn-submit{
            font-size: 1rem;
            border: 2px solid gray;
            font-weight: bold;
            color: gray;
            border-radius: 10px;
            padding: .3rem;
        }

        .btn-prev{
            color: orange;
            border-color: orange;
        }

        .btn-next{
            color: green;
            border-color: green;
        }

        .btn-submit{
            color: #00a2ff;
            border-color: #00a2ff;
        }
    }
}

.anonymous-container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000057;
    backdrop-filter: blur(10px);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 2rem;

    @media (max-width:570px) {
        justify-content: flex-start;
        padding-top: 2.5rem;
    }

    .container-info{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-size: 2rem;
        align-items: center;
        font-weight: bold;
        color: #c42408;

        i{
            font-size: 3rem;
        }

        @media (max-width:400px) {
            font-size: 1.5rem;

            i {
                font-size: 2rem;
            }
        }

    }

    .btn-set-info{
        color: whitesmoke;
        border: 2px solid whitesmoke;
        padding: .5rem;
        border-radius: .5rem;
        font-weight: bold;
        margin-inline: 1rem;

        &:hover{
            background-color: whitesmoke;
            color: #000;
            transition: all .3s;
        }
    }
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
        position: absolute;
        border: 4px solid #fff;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

        &:nth-child(2) {
            animation-delay: -0.5s;
        }
    }

    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 0;
        }

        4.9% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 0;
        }

        5% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }

        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

.loader-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.238);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

@mixin lds-ring($color: #fff) {
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid $color;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $color transparent transparent transparent;
    }

    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }

    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }

    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

.loader-page:not(.d-none) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    width: 120px;
    height: 120px;
    z-index: 31;
    display: flex;
    justify-content: center;
    align-items: center;
    @include lds-ring(gray);
}

#main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    /* Assuming 1rem = 16px */
    position: fixed;
    top: 0;
    width: 100%;
    border-bottom: 1px solid #d1d5db;
    /* Replace with the desired color value */
    backdrop-filter: blur(4px);
    /* You can adjust the blur amount as needed */
    background-color: rgba(209, 213, 219, 0.25);
    /* Replace with the desired color value and opacity */
    z-index: 20;

    .csu-plataform-btn,.csu-line-green-btn {
        border: 2px solid #f97316;
        /* Replace with the desired color value */
        border-radius: 0.25rem;
        /* Adjust border-radius as needed */
        padding: 0.25rem 0.5rem;
        /* Adjust padding as needed */
        background-color: #f97316;
        /* Replace with the desired color value */
        color: white;
    }

    .csu-line-green-btn{
        background-color: green;
        border-color: green;
    }

    .nav-default,.container-nav {
        font-family: 'Alef', sans-serif;
        /* Specify your desired font-family */
        display: flex;
        gap: 1rem;
        /* Adjust gap as needed */
        align-items: center;
        font-weight: bold;
        font-size: 1rem;
        /* Adjust font-size as needed */
        color: rgba(0, 0, 0, 0.7);
        /* Adjust color as needed */
    }

    .nav-link{
        transition: color .3s;
        
        &:hover {
            //color: orange;
            color: black;
        }
    }

    .hambuquer-menu{
        font-size: 1.5rem;
        background-color: rgb(203, 203, 203);
        padding: .1rem .5rem;
        border-radius: 5px;
        display: none;
    }

    @media (max-width:1002px) {
        .hambuquer-menu{
            display: block;
        }

        .container-nav{
            position: absolute;
            display: flex;
            flex-direction: column;
            //backdrop-filter: blur(4px);
            background-color: #cecece;
            padding: 1rem 4rem;
            top:4rem;
            right: -1500px;
            transition: right .5s;

            &.show{
                right: 5px;
            }
        }

        .nav-link {

            &:hover {
                color: #00a2ff;
            }
        }
    }

    @media (max-width:310px) {
        .container-nav {
            padding: 0;
            width: 100%;
            right: -1500px;
            padding-bottom: 1rem;

            &.show {
                right: 0;
            }
        }
    }
}

#header-banner{
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;

    &::before {
        content: "";
        position: absolute;
        background-image: url("../img/bg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        background-color: rgba(109, 54, 0, 0.251);
        backdrop-filter: blur(10px);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .container-logos{
        position: absolute;
        display: flex;
        gap: 1rem;
        top: 39%;
        left: 50%;
        padding: 1rem;
        transform: translate(-50%,-50%);
        background-color: white;
        opacity: .2;
        z-index: 8;
        border-radius: 10px;
        width: 798px;
        flex-wrap: wrap;
        justify-content: center;

        @media (max-width:828px) {
            width: 98%;
        }

        img{
            max-width: 100%;
        }
    }

    .main-container {
        position: relative;
        margin-top: 9rem;
        z-index: 10;
        text-align: center;
        width: 920px;

        .main-title{
            font-size: 3.5rem;
            font-weight: bold;
            color: aliceblue;
            margin-bottom: 4rem;
        }

        .label-question{
            font-size: 2rem;
            font-weight: lighter;
            display: block;
            margin-bottom: 2rem;
            color: rgb(255, 196, 0);
            text-shadow: 0px 0px 5px black;
        }

        .container-search{
            display: flex;
            align-items: center;
            width: max-content;
            margin-inline: auto;
            border-radius: 50px;
            background-color: white;
            overflow: hidden;
            margin-top: 2rem;

            .input-search{
                border: none;
                padding: .5rem;
                font-size: 1.3rem;
                width: 30rem;
                background-color: transparent;

                &::placeholder{
                    color: gray;
                }

                &:focus{
                    outline: none;
                }
            }

            .btn-search{
                background-color: rgb(0, 157, 255);
                font-size: 2rem;
                padding: .2rem;
                width: 5rem;
                color: white;
            }
        }

        .faq-container{
            margin-top: 1rem;

            .faq-title{
                font-weight: bold;
                font-size: 1.2rem;
                margin-bottom: 1rem;
                color: aliceblue;
                text-shadow: 0px 0px 5px black;
            }

            .questions-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1rem;

                .card-question{
                    font-size: 1.2rem;
                    //background-color: rgba(137, 137, 137, 0.442);
                    background-color: rgba(0, 157, 255, 0.129);
                    color: white;
                    padding: .5rem;
                    border-radius: 10px;
                    text-shadow: 0px 0px 5px black;
                    cursor: pointer;
                    border: 1px solid rgba(220, 220, 220, 0.251);
                }
            }
        }

        .container-actions {
            margin-top: 5rem;
            display: flex;
            gap: 1rem;
            width: max-content;
            margin-inline: auto;
            align-items: center;

            .btn-reclam{
                background-color: rgba(255, 119, 0, 0.658);
                color: white;
                padding: .5rem;
                font-size: 1.5rem;
                border-radius: 5px;
            }

            .btn-contact{
                background-color: rgba(20, 19, 17, 0.545);
                color: white;
                padding: .5rem;
                font-size: 1.5rem;
                border-radius: 5px;

                img{
                    width: 220px;
                }
            }

            &:last-of-type{
                margin-top: 2rem;
            }
        }

        @media (max-width:920px) {
            width: 100%;

            // .container-search{
            //     width: 100%;
            // }
        }

        @media (max-width:610px) {
            .main-title {
                font-size: 3rem;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            .container-search {
                width: 95%;

                .input-search {
                    flex-grow: 1;
                }
            }

            .questions-container {
                padding: 1rem;
            }

            .container-actions {
                width: 100%;
                flex-wrap: wrap;
                justify-content: center;
            }
        }

        @media (max-width:330px) {
            .main-title {
                font-size: 2rem;
            }

            .container-search {
                flex-wrap: wrap;
                border-radius: 0;

                .input-search {
                    font-size: 1rem;
                }

                .btn-search {
                    width: 100%;
                    padding: 0px;
                    font-size: 1.5rem;
                }
            }
        }
    }
}

.title-section {
    font-size: 2rem;
    color: rgb(71, 71, 71);
    text-align: center;
    margin-bottom: 3rem;
    border-bottom: 5px solid orange;
    padding-bottom: 1rem;
    max-width: max-content;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-inline: auto;
}

.title-secondary{
    font-size: 1.6rem;
    text-align: center;
    color: rgba(71, 71, 71, 0.726);
}

.container-padding{
    max-width: 1400px;
    margin-inline: auto;
}

.container-group {
    position: relative;
    z-index: 0;

    &::before {
        content: "";
        position: absolute;
        background-image: url("/src/img/bgMinimalistic-removebg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: .2;
        z-index: 0;
    }
}

.container-search-claims{
    z-index: 2;
    margin-top: 6rem;
    background-color: rgba(128, 128, 128, 0.459);
    width: 1400px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;

    .feedback-error {
        font-size: .8rem;
    }
    
    .form-control {
        padding: .3rem;
        font-size: 1rem;
    }

    @media (max-width:1436px) {
        width: 98%;
    }
    

    .form-search-by-id{
        display: flex;
        gap: 1rem;
        align-items: center;

        .btn-search-claim {
            background-color: #00a2ff;
            color: white;
            border-radius: 5px;
            font-size: 1rem;
            padding: .3rem;
            align-self: flex-start;
            margin-top: .1rem;

            @media (max-width:560px) {
                width: 100%;
            }
        }

        @media (max-width:770px) {
            flex-direction: column;
            gap: .2rem;

            .btn-search-claim {
                align-self: center;
                margin-top: 0;
                margin-bottom: 1rem;
            }
        }
    }

    @media (max-width:560px) {
        .form-search-by-json{
            width: 100%;
        }
    }

    .btn-load-json-file{
        color: white;
        font-weight: bold;
        border: 5px dashed white;
        padding: 1rem 5rem;
    }
}

.container-claim-info{
    display: flex;
    gap: 1rem;
    z-index: 2;
    margin-top: 1rem;
    width: 1400px;
    @media (max-width:1436px) {
        width: 98%;
    }

    @media (max-width:1407px) {
        flex-direction: column;
    }
    >div{
        background-color: rgba(128, 128, 128, 0.459);
        border-radius: 10px;
        min-height: 360px;
    }

    .container-list-obs{
        width: 25rem;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding-inline: .3rem;

        .label-not-found{
            color: white;
            width: 100%;
            text-align: center;
        }

        @media (max-width:1407px) {
            width: 100%;
            min-height: max-content;
            .label-not-found {
                font-size: 2rem;
            }
        }

        .btn-send-answer{
            color: #fff;
            background-color: #f97316;
            border: 2px solid #f97316;
            border-radius: .25rem;
            padding: .25rem .5rem;
            margin-top: 1rem;

            @media (max-width:1407px) {
                margin-bottom: 1rem;
            }
        }

        .list-obs {
            min-height: max-content;
            font-size: 1rem;
            max-height: 40rem;
            width: 100%;
            flex-grow: 1;
            overflow-y: auto;
            padding: 1rem;
            border-radius: .5rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            @media (max-width:1407px) {
                order: -1;
                flex-direction: row;
                overflow-y: hidden;
                overflow-x: auto;
                min-height: 200px;
                max-height: 200px;
            }
    
            .obs-item {
                background-color: gray;
                color: white;
                font-weight: bold;
                padding: 1rem;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                @media (max-width:1407px) {
                    flex-basis: 360px;
                    flex-shrink: 0;
                    height: max-content;
                }
    
                .obs-text {
                    font-size: 1rem;

                    @media (max-width:1407px) {
                        max-height: 100px;
                        padding-inline: 1rem;
                        overflow-y: auto;
                    }
                }
    
                &.clgr {
                    background-color: #009DFF;
                }
    
                &.ccgr {
                    background-color: green;
                }
    
                &.reclamante {
                    background-color: rgb(155, 101, 2);
                }
    
                .btn-del-obs {
                    background-color: tomato;
                    font-weight: bold;
                    color: white;
                    border-radius: 5px;
                    font-size: 1.2rem;
                }
            }
        }
    }

    .container-tracker{
        flex-grow: 1;
        max-height: 360px;

        @media (max-width:858px) {
            max-height: max-content;
        }

        .container-title{
            font-size: 2rem;
            text-align: center;
            color: white;
            font-weight: bold;
        }

        .tracker-progress-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2rem;
            margin-top: 5rem;
            margin-bottom: 5rem;

            @media (max-width:1106px) {
                gap: 1rem;
            }

            @media (max-width:858px) {
                flex-direction: column;
                gap: .5rem;
            }

            .tracker-item-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: .1rem;
                font-size: 1.2rem;

                &.current::after {
                    content: "Atual";
                    font-weight: bold;
                    color: white;
                }

            }

            .tracker-item-number {
                background-color: white;
                color: gray;
                font-weight: bold;
                width: 5rem;
                height: 5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                border-radius: 100%;

                &.current {
                    background-color: orange;
                    color: white;
                }

                &.complete {
                    background-color: green;
                    color: white;
                }

                &.back {
                    background-color: tomato;
                    color: white;
                }

            }

            .tracker-item-label {
                text-align: center;
                color: white;
            }

            .fa-solid {
                font-size: 4rem;
                color: white;

                @media (max-width:858px) {
                    position: relative;
                    transform: rotate(90deg);
                }
            }
        }
    }
}

#docs{
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;

    .documents-container{
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        gap: 1rem;
        flex-wrap: wrap;

        .card-docs{
            display: flex;
            flex-direction: column;
            position: relative;
            border: 1px solid gainsboro;
            background-color: white;
            border-radius: 10px;
            text-align: center;
            flex-basis: 250px;

            .container-icon{
                background-color: gainsboro;
                font-size: 4rem;
                text-align: center;
                //padding: 1rem;
                position: relative;
                height: 160px;
                overflow: hidden;

                .img-preview-bo{
                    width: 100%;
                }

                .img-pdf-logo{
                    position: absolute;
                    top: 30px;
                    left: 10px;
                }
            }

            .container-title{
                font-size: 1rem;
                padding: .5rem;
            }

            .fa-file-pdf{
                color: #c42408;
            }

            .link-read-more{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                backdrop-filter: blur(3px);
                opacity: 0;
                transition: opacity .5s;

                i{
                    background-color: #00a2ff;
                    font-size: 2rem;
                    color: white;
                    padding: .2rem 2rem;
                    border-radius: 10px;
                }

                &:hover{
                    opacity: 1;
                }
            }

            @media (max-width:1450px) {
                flex-basis: 200px;

                .container-title{
                    font-size: 1.2rem;
                }
            }
        }

        @media (max-width:1195px) {
            padding-inline: 1rem;
        }
    }
}

#indicator{
    //margin-bottom: 4rem;

    .container-indicadores{
        background-color: #00a2ff;
        padding: 1rem 0;
        backdrop-filter: blur(20px);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        &::before{
            content: "";
            background-image: url("../img/minimalisticLines.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            filter: grayscale(1) invert(1);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            opacity: .1;
            z-index: 0;
        }

        .container-counter{
            display: flex;
            justify-content: center;
            gap: 2rem;
            position: relative;
            z-index: 1;
            text-align: center;
            flex-wrap: wrap;

            .circle-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 1.2rem;
                padding: 1rem;
                color: white;
                flex-basis: 10rem;

                .counter-circle{
                    font-size: 3rem;
                    width: 13rem;
                    height: 10rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(255, 196, 87, 0.188);
                    border-radius: 10px;

                    .total-label{
                        font-size: 2.2rem;
                    }

                    @media (max-width:365px) {
                        width: 100%;
                        max-width: 100%;
                    }
                }

                .counter-name{
                    font-weight: bold;
                    margin-top: .5rem;
                    font-size: 1.1rem;
                }

                @media (max-width:1510px) {
                    font-size: .9rem;

                    .counter-circle {
                        font-size: 2rem;
                        width: 15rem;
                        height: 10rem;
                    }
                }
            }
        }

        .view-more{
            color: white;
            background-color: orange;
            padding: .5rem;
            font-size: 1.2rem;
            font-weight: bold;
            border-radius: 10px;
            position: relative;
            z-index: 1;
        }

    }
}

#about,#mfids{
    padding-top: 4rem;
    margin-bottom: 4rem;
    //padding-bottom: 4rem;
    position: relative;

    .mfis-bg{
        z-index: -1;
        opacity: 0.1;
        position: absolute;
        filter: blur(3px);
    }

    .mfis{
        //margin-top: 5rem;
        margin-inline: auto;
        margin-bottom: 2rem;
    }

    // &::before{
    //     content: "";
    //     position: absolute;
    //     background-image: url("/src/img/bgMinimalistic-removebg.png");
    //     background-repeat: no-repeat;
    //     background-position: center;
    //     background-size: cover;
    //     width: 100%;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     opacity: .3;
    //     z-index: 0;
    // }

    .description-text-container{
        margin-bottom: 4rem;
        text-align: center;
        font-size: 1.2rem;
        padding: 0 2rem;

        @media (max-width:450px) {
            font-size: 1rem;
        }

        .mfids-p{
            margin-top: 2rem;
        }
    }

    .cointainer-team{
        display: flex;
        justify-content: center;
        text-align: center;
        gap: 1rem;

        @media (max-width:830px) {
            flex-wrap: wrap;
            padding: 0 2rem;
        }
    }

    .csu-team-container{
        .title-secondary{
            margin-bottom: 2rem;
        }
        .card-team {
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 1;
            align-items: center;
            border: 1px solid rgba(220, 220, 220, 0.919);
            width: 15rem;
            border-radius: 5px;
            background-color: white;
            box-shadow: 0px 0px 2px orange;

            @media (max-width:300px) {
                width: 98%;
            }
        }

        .img-member{
            width: 15rem;
            max-width: 100%;
            border-radius: 100%;
            object-fit: cover;
        }

        .member-info{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            margin-bottom: 1rem;

            .member-name{
                font-weight: bold;
                font-size: 1.2rem;
                color: orange;
                text-shadow: 0px 0px 2px gainsboro;
            }

            .member-role{
                color: #00a2ff;
            }
        }
    }
    
}

#about {
    padding-top: 1rem;
}

#partners,#tutela{
    margin-bottom: 4rem;
    position: relative;

    .partner-container{
        display: flex;
        //flex-wrap: wrap;
        align-items: center;
        //justify-content: center;
        gap: 1rem;
        overflow-y: auto;
        margin-inline: 3rem;
        margin-bottom: 2rem;
        position: relative;

        .glide__track,.glide__slides,.glide__slide{
            width: 100%;
        }

        .img-partner{
            object-fit: cover;
            max-width: 100px;
            //filter: grayscale(1);
            //opacity: .7;
            //transition: all .3s;
            //cursor: pointer;

            &.gov-icon{
                max-width: 300px;
            }

            &.wb{
                max-width: 200px;
            }

            &.cs{
                max-width: 150px;
            }

            // &:hover{
            //     position: relative;
            //     filter: grayscale(0);
            //     opacity: 1;
            //     transform: scale(3);
            //     z-index: 1;
            // }

            @media (max-width:396px) {
                &.gov-icon{
                    max-width: 100%;
                }
            }

        }
    }
}

#faqs{
    //margin-bottom: 4rem;
    .faq-content{
        background-color: orange;
        padding: 1rem 0;
        backdrop-filter: blur(20px);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        min-height: 305.6px;
    
        &::before {
            content: "";
            background-image: url("../img/minimalisticLines.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            filter: grayscale(1) invert(1);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            opacity: .1;
            z-index: 0;
        }

        .container-padding{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 1rem;
            position: relative;
            z-index: 1;
        }

        .faq-item{
            color: #fff;
            text-shadow: 0 0 5px #000;
            cursor: pointer;
            background-color: #009dff9e;
            border: 1px solid #dcdcdc40;
            border-radius: 10px;
            padding: .5rem;
            font-size: 1.5rem;
            text-align: center;
            margin: 0 1rem;

            @media (max-width:520px) {
                font-size: 1.2rem;
            }
        }
    }
}

#contact{
    //margin-bottom: 4rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    position: relative;
    z-index: 1;

    // &::before {
    //     content: "";
    //     position: absolute;
    //     background-image: url("/src/img/bgMinimalistic-removebg.png");
    //     background-repeat: no-repeat;
    //     background-position: center;
    //     background-size: cover;
    //     width: 100%;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     opacity: .3;
    //     z-index: 0;
    // }
    .form-contact{
        width: max-content;
        margin-inline: auto;
        border: 2px solid gainsboro;
        padding: 1rem;
        background-color: white;
        position: relative;
        z-index: 1;

        .action-container{
            display: flex;
            align-items: center;
            gap: 1rem;
            justify-content: center;
            flex-wrap: wrap;
        }

        @media (max-width:750px) {
            width: 95%;
        }
    }

    .form-group-inline{
        display: flex;
        gap: 1rem;
        @media (max-width:570px) {
            flex-wrap: wrap;
            >div{
                width: 100%;
            }
        }
    }

    .form-group{
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        flex-grow: 1;
    }

    .form-control{
        border: 1px solid gainsboro;
        font-size: 1.2rem;
        padding: .5rem;
        &:focus{
            outline: none;
            border: 2px solid orange;
        }
    }

    .btn-groups{
        display: flex;
        justify-content: flex-end;
        gap: 1rem;

        .btn{
            font-size: 1.2rem;
            font-weight: bold;
            padding: .3rem 2rem;
            border-radius: 5px;

            @media (max-width:380px) {
                width: 100%;
            }
        }

        .btn-submit{
            background-color: #00a2ff;
            color: white;
        }

        .btn-cancel{
            border: 2px solid tomato;
            color: tomato;
        }

        @media (max-width:410px) {
            justify-content: center;
            flex-wrap: wrap;
        }
    }
}

#location{
    position: relative;
    z-index: 1;

    .mapgoogle{
        width: 100%;
        pointer-events: none;
    }
}

#footer{
    background-color: black;
    padding: 1rem 0;
    backdrop-filter: blur(20px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-height: 305.6px;
    color: white;
    text-align: center;

    &::before {
        content: "";
        background-image: url("../img/minimalisticLines.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        filter: grayscale(1) invert(1);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        opacity: .1;
        z-index: 0;
    }

    .links-container{
        z-index: 1;

        .title-secondary{
            color: whitesmoke;
            margin-bottom: 2rem;
        }

        .social-link-container{
            display: flex;
            justify-content: center;
            gap: 1rem;
            font-size: 4rem;

            @media (max-width:350px) {
                flex-wrap: wrap;
                font-size: 2.5rem;
            }
        }
    }

    .adicional-info{
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        
        .fa-solid{
            font-size: 1.5rem;
            color: rgba(220, 220, 220, 0.377);
        }

        .info-item{
            display: flex;
            align-items: center;
            gap: .5rem;
            font-size: 1.2rem;
        }

        .info-label{
            margin-bottom: .25rem;
        }
    }

    #copyrigth{
        z-index: 1;
        font-size: 1.5rem;
        margin-top: 1rem;
        color: rgba(220, 220, 220, 0.429);
    }
}

.iframe-indicadores{
    z-index: 2;
    //max-width: fit-content;
    width: 100%;
    height: 93vh;
    //max-height: 763px;
    margin-top: 4rem;
    position: relative;
    object-fit: cover;
    outline: none;
    
}

.help-container{
    position: fixed;
    background-color: white;
    bottom: 5rem;
    right: -20rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.647);
    z-index:11;
    transition: right .5s;

    &.show{
        right: 20px;
    }

    .container-option-helper{
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .link-suport{
            font-size: 1.2rem;
            display: flex;
            align-items: center;
            gap: .5rem;
            border: 2px solid orangered;
            color: orangered;
            font-weight: bold;
            padding: .3rem;
            border-radius: 5px;

            &.green-phone{
                color: green;
                border-color: green;
            }

            &.track-claim{
                //background-color: #D26F1E;
                color:#D26F1E;
                border-color: #D26F1E;
            }

            &.blue-link{
                // color: #009DFF;
                // border-color: #009DFF;
                color: #D26F1E;
                border-color: #D26F1E;
            }
        }
    }

    .help-container-closer{
        position: absolute;
        background-color: #000;
        color: white;
        font-size: 2rem;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        padding-bottom: 6px;
        cursor: pointer;
        top:-16px;
        right: -13px;
    }

    @media (max-width:305px) {
        width: calc(100% - 40px);
    }
}

.action-fixe-container{
    position: fixed;
    right: 1.5rem;
    bottom: 10px;
    display: flex;
    justify-content: center;
    gap: 2rem;
    font-size: 2.5rem;
    z-index: 11;

    >div{
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        cursor: pointer;
    }

    .suporte-action{
        background-color: #00a2ff;
        color: white;
    }

    .scroll-to-op{
        background-color: #2f2f2f;
        color: white;
    }
}